import { RootState } from "shared/store/reducer"

/** Navigation */
export const getcontents = (state: RootState) => state.contents;
export const getShowReturnButton = (state: RootState) => state.navigation.showReturnButton;

/** Stream */
export const getRequestingStreams = (state: RootState) => state.streams.requestingStreams;
export const getStations = (state: RootState) => state.streams.stations;
export const getTV = (state: RootState) => state.streams.tv;
export const getApiServer = (state: RootState) => state.streams.apiServer;
export const getIcecastServer = (state: RootState) => state.streams.icecastServer;
export const getIcecastSources = (state: RootState) => state.streams.icecastSources;

/** Player */
export const getPlayer = (state: RootState) => state.player.player;
export const getPlayerStatus = (state: RootState) => state.player.playerStatus;
export const getMuted = (state: RootState) => state.player.muted;
export const getPlaying = (state: RootState) => state.player.playing;
export const getSound = (state: RootState) => state.player.sound;
export const getTrack = (state: RootState) => state.player.track;
export const getNowPlaying = (state: RootState) => state.player.nowPlaying;
export const getTrackProgress = (state: RootState) => state.player.trackProgress;
export const getTrackDuration = (state: RootState) => state.player.trackDuration;
export const getTrackTimeLeft = (state: RootState) => state.player.trackTimeLeft;
export const getTrackCurrentTime = (state: RootState) => state.player.trackCurrentTime;
