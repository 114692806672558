import ReactPixel from 'react-facebook-pixel'
import { fbPixelId } from '../config/app'

export const initializeFbPixel = () => {
  const options = {
    autoConfig: true,
    debug: process.env.NODE_ENV === 'production'
  }
  ReactPixel.init(fbPixelId, {}, options)
}

export const setPixelView = (path) => {
  ReactPixel.pageView()
}

export const setPixelEvent = (args) => {
  const { event, data } = args
  if (typeof event === 'undefined' || typeof data === 'undefined') {
    return false
  }
  ReactPixel.track(event, data)
}
