import { staticResourcesPath } from './app'

export const liveVideoId = 'radio-live-video'

export const liveVideo = {
  description: '',
  embed: '',
  file_format: '',
  genre: '',
  icon_off: '',
  icon_on: '',
  id_embed: '',
  id_provider: '',
  id_source: '',
  id_status: '',
  id_type: '',
  provider: '',
  public_name: '',
  source: '',
  src: '',
  stats_url: ' ',
  status: 'off-line',
  stream_id: liveVideoId,
  type: '',
  preview_card: {
    src: `${staticResourcesPath}/images/preview_card_live_video.jpg`,
    width: 1200,
    height: 630
  }
}
