import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuToggle,
  IonButton
} from '@ionic/react'
import {
  close
} from 'ionicons/icons'
import {
  InavigationOption,
  getStations,
  getTV
} from 'shared'
import './styles.scss'

interface IProps {
  option: InavigationOption;
}
/**
 * This component renders Navbar used on Layout
 * @returns React Component
 */
const Navbar = () => {
  const stations = useSelector(getStations);
  const tv = useSelector(getTV);

  /**
   * Components
   */
  const SimpleOption: FC<IProps> = ({ option }) => (
    <IonItem lines='none'>
      <IonMenuToggle>
        <IonLabel>
          <Link
            to={option.url}
            aria-label={option.label}
          >
            {option.name}
          </Link>
        </IonLabel>
      </IonMenuToggle>
    </IonItem>
  );
  
  return (
    <IonMenu
      side='end'
      contentId='main'
      type='overlay'
      className='mobile main-menu'
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonMenuToggle>
              <IonButton>
                <IonIcon size='48' icon={close} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className='categories'>
          <SimpleOption
            option={{
              type: "simple",
              name: "Dashboard",
              url: "/",
              label: "Ir al Dashboard"
            }} 
          />
        </IonList>
        <IonList className='categories'>
          {stations.map((option, index) => (
            <SimpleOption
              option={{
                type: "simple",
                name: option.public_name,
                url: `/estacion/${option.url_name}`,
                label: `Ver datos de ${option.public_name}`
              }} 
              key={`nav-option-${index}`}
            />))}
        </IonList>
        {tv && 
          <IonList className='categories'>
            <SimpleOption
              option={{
                type: "simple",
                name: tv.public_name,
                url: `/tv`,
                label: `Ver datos de ${tv.public_name}`
              }} 
            />
          </IonList>}
      </IonContent>
    </IonMenu>
  )
}

export default Navbar
