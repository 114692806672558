import { FC, useState, useRef, useEffect } from 'react';
import {
  IonContent,
  useIonViewDidEnter,
  useIonViewWillLeave
} from '@ionic/react'

import {
  setRootMetaTags,
  setView,
  setPixelView
} from "shared";

interface IProps {
  children: any;
  className?: string;
  trackAnalytics?: boolean;
  onEnter?: Function;
  onLeave?: Function;
  metadata?: "default" | false
}
const ContentWraper: FC<IProps> = ({
  children,
  className = '',
  trackAnalytics = true,
  onEnter,
  onLeave,
  metadata = "default",
  ...props
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  
  /**
   * Allow view to display if enter view
   */
  useIonViewDidEnter(() => {
    // Show view
    setIsVisible(true)
    // Track analytics
    if (trackAnalytics) {
      setView(window.location.pathname)
      setPixelView()
    }

    // Update metadata
    if (metadata === "default") setRootMetaTags()
    
    // Excecute on enter, if is defined
    onEnter && onEnter();
  });

  /**
   * Disallow view to display if enter view
   */
  useIonViewWillLeave(() => {
    setIsVisible(false);
    onLeave && onLeave();
  });

  useEffect(() => {
    try {
      contentRef.current && contentRef.current.scrollToTop();
    } catch (e) {
      console.error(e);
    }
  }, [contentRef]);

  if (isVisible === false) return null

  return (
    <IonContent
      ref={contentRef}
      fullscreen
      className={`page-wrapper ${className}`}
      {...props}
    >
      {children}
    </IonContent>
  )

}

export default ContentWraper;
