
import { FC } from 'react';
import { 
  IonPage
} from '@ionic/react';
import { Header, Player } from '../components';

interface IProps {
  children: any;
}
const DefaultLayout: FC<IProps> = ({ children }) => {

  return (
    <IonPage>
      <Header />
      {children}
      <Player />
    </IonPage>
  )
}

export default DefaultLayout
