import { FC, memo } from "react";
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  IonRange,
  IonLabel
} from '@ionic/react';
import { RangeValue } from '@ionic/core';
import {
  getTrack,
  getTrackProgress,
  getTrackCurrentTime,
  setTrackProgress,
  getTrackDuration,
  getTrackTimeLeft
} from 'shared';

import './styles.scss'

interface RangeChangeEventDetail {
  value: RangeValue;
}
interface RangeCustomEvent extends CustomEvent {
  detail: RangeChangeEventDetail;
  target: HTMLIonRangeElement;
}
interface IProps {}
const DesktopPlaybackBar: FC<IProps> = () => {
  const track = useSelector(getTrack)
  const trackProgress = useSelector(getTrackProgress)
  const trackCurrentTime = useSelector(getTrackCurrentTime)
  const trackTimeLeft = useSelector(getTrackTimeLeft)
  const trackDuration = useSelector(getTrackDuration)
  const dispatch = useDispatch()

  const changeProgress = (e: RangeCustomEvent) => {
    if (track === null || track.sound === null) return;
    if (e.target?.classList?.contains('range-pressed')) {
      track.sound.setPosition(e.detail.value)
      dispatch(setTrackProgress(track.sound.position))
    }
  }  

  return (
    <div className="desktop-playback-bar">
      <IonLabel className='time-elapsed'>{trackCurrentTime || "00:00"}</IonLabel>
      <IonRange
        disabled={track === null || track.windable === false}
        value={trackProgress}
        onIonChange={changeProgress}
        min={0}
        max={trackDuration}
      />
      <IonLabel className='time-left'>{trackTimeLeft || "00:00"}</IonLabel>
    </div>
  );
}

export default memo(DesktopPlaybackBar)
