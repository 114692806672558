import { 
  http,
  apiHeaders,
  apiBasePath,
  IStreamResource,
  IicecastAllInstances,
  IicecastInstance,
  serverStatsUrl,
  secureServerStatsUrl
} from 'shared';

const servicePath = 'web/streaming';

interface IResourceParams {
  stream_id?: string;
  public_name?: string;
  id_type?: string;
  type?: "audio"  | "video";
  id_source?: string;
  source?: string;
  id_embed?: string;
  embed?: string;
  id_provider?: string;
  provider?: string;
  file_format?: string;
  id_status?: string;
  status?: string;
}

export const StreamingAPI = {
  getStreamResource: async (requestParams: IResourceParams): Promise<IStreamResource[] | null> => {
    const url = `${apiBasePath}/${servicePath}`
    const data = requestParams || {}
    return await http(url, 'get', data, apiHeaders)
      .then((response) => {
        const request = response.data;
        return request.data.resources;
      })
      .catch((error) => {
        console.error(error);
        return null;
      })
  },
  getStreamStats: async (stats_url: string): Promise<IicecastInstance | null> => {
    return await http(stats_url, 'get', {})
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return null;
      });
  },
  getServerStats: async (): Promise<IicecastAllInstances | null>  => {
    return await http(serverStatsUrl, 'get')
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return null;
      });
  },
  getSecureServerStats: async (): Promise<IicecastAllInstances | null>  => {
    return await http(secureServerStatsUrl, 'get')
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return null;
      });
  },
  getApiServerStats: async (): Promise<IStreamResource[] | null>  => {
    return await http(serverStatsUrl, 'get')
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return null;
      });
  },
  getApiSecureServerStats: async (): Promise<IStreamResource[] | null>  => {
    return await http(secureServerStatsUrl, 'get')
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return null;
      });
  } 
}
