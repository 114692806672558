import { FC, memo } from 'react';
import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonLabel,
  IonTabButton
} from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { YokIcon } from 'shared';
import { routes } from './routes';
import { Navbar } from 'mobile/components';
import './styles.scss';

interface IProps {}

const AppMobile: FC<IProps> = () => {
  return (
    <>
      <Navbar />
      <IonTabs>
        <IonRouterOutlet>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
            >
              <route.layout>
                <route.component/>
              </route.layout>
            </Route>
          ))}
          <Route exact path="/">
            <Redirect
              to={{
                pathname: "/home",
              }}
            />
          </Route>
          <Redirect to='/home' />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton href="/home">
            <YokIcon icon='house-door-fill'/>
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton href="/estaciones">
            <YokIcon icon='camera-video-fill' />
            <IonLabel>Estaciones</IonLabel>
          </IonTabButton>
          <IonTabButton href="/tv">
            <YokIcon icon='live' />
            <IonLabel>TV</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  )
};

export default memo(AppMobile);
