import { 
  FC,
  memo
} from "react";
import {
  IonFooter
} from "@ionic/react";
import {
  DesktopNowPlaying,
  DesktopPlaybackBar,
  DesktopPlayButton,
  WindBackwardButton,
  WindForwardButton,
  MuteButton
} from "shared";
import "./styles.scss";

interface IProps {};

const Player: FC<IProps> = () => {
  return (
    <IonFooter className="player desktop">
      <DesktopNowPlaying />

      <DesktopPlaybackBar />

      <div className="controls-container">
        <MuteButton />
        <WindBackwardButton />
        <DesktopPlayButton />
        <WindForwardButton />
      </div>
    </IonFooter>
  )
}

export default memo(Player);
