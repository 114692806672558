import { FC, useEffect, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  StreamingAPI,
  getTrack,
  setNowPlaying
} from "shared"

interface IProps {}
/**
 * This component update data of current stream, when currentTrack have a stats_url defined
 * @returns void
 */
const NowPlayingUpdater: FC<IProps> = () => {
  const track = useSelector(getTrack);
  const interval = useRef<NodeJS.Timer>();
  const dispatch = useDispatch();

  const updateStats = async () => {
    if (track === null) return;
    const streamStats = await StreamingAPI.getStreamStats(track.stats_url);

    if (streamStats === null) return;
    if (streamStats.icestats === undefined) return;
    if (streamStats.icestats.source === undefined) return;
    if (streamStats.icestats.source.title === undefined) return;

    const splitedTitle = streamStats.icestats.source.title.split('-');
    
    dispatch(setNowPlaying({
      artwork: track.artwork[0].src || "",
      artist: splitedTitle[0] ? splitedTitle[0].trim() : '',
      artist_content_url: track.artist_content_url,
      title: splitedTitle[1] ? splitedTitle[1].trim() : '',
      title_content_url: track.title_content_url
    }));
  }

  useEffect(() => {
    if (track === null) return;
    dispatch(setNowPlaying({
      artwork: track.artwork[0].src || "",
      artist: track.artist,
      artist_content_url: track.artist_content_url,
      title: track.title,
      title_content_url: track.title_content_url
    }));
    if (track.stats_url === "" && interval.current) clearInterval(interval.current);
    if (track.stats_url !== "") {
      updateStats();
      if (interval.current) clearInterval(interval.current)
      interval.current = setInterval(updateStats, 15000);
    }
    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [track])

  return (<></>);
}

export default memo(NowPlayingUpdater);
