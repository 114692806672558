import { FC, memo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { IonButton } from '@ionic/react';
import {
  YokIcon,
  getMuted,
  getTrack,
  setMuted
} from "shared";

import './styles.scss'

interface IProps {}
const MuteButton: FC<IProps> = () => {
  const muted = useSelector(getMuted);
  const track = useSelector(getTrack);
  const dispatch = useDispatch();

  const mute = () => {
    if (track === null) return;
    if (track.sound.muted === false) track.sound.mute();
    dispatch(setMuted(true))
  }

  const unmute = () => {
    if (track === null) return;
    if (track.sound.muted) track.sound.unmute();
    dispatch(setMuted(false))
  }

  const Button: FC<{
    icon: React.ReactNode,
    onClick: React.MouseEventHandler,
    disabled?: boolean
  }> = ({
    icon,
    onClick,
    disabled = false
  }) => (
    <IonButton 
      className="toogle-volume"
      slot='icon-only'
      fill='clear'
      shape="round"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </IonButton>
  )

  if (track === null) return <Button disabled onClick={() => {}} icon={<YokIcon icon="volume_up" />} />

  if (muted) return <Button onClick={unmute} icon={<YokIcon icon="volume_off" />} />

  if (muted === false) return <Button onClick={mute} icon={<YokIcon icon="volume_up" />} />

  return <Button disabled onClick={() => {}} icon={<YokIcon icon="volume_up" />} />
}

export default memo(MuteButton);
