import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from "@ionic/react";
import { 
  getTV,  
  ContentWraper,
  FullScreenLoader,
  StatusIcon,
  EmbedFrame
} from 'shared';
import './styles.scss';

interface IProps {
  route: any;
}

const TvPage: FC<IProps> = () => {
  const tv = useSelector(getTV);
  console.info('tv', tv);
  return (
    <ContentWraper className='tv-page'>
      <FullScreenLoader loading={tv === null} />

      {tv !== null &&
        <IonGrid fixed>
          <IonRow>
            <IonCol size="12">
              <h1>Datos de la Estación <StatusIcon isAvailable={tv?.isAvailable} /></h1>
              {tv?.errorMessage && <p className='error-message'>{tv?.errorMessage}</p>}
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Nombre:
                <span>{tv?.public_name || "-"}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Descripción:
                <span>{tv?.description || "-"}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <figure>
                <figcaption>
                  <p>Imagen de Preview:</p>
                </figcaption>
                {tv?.preview_image ? <IonImg src={tv?.preview_image} /> : "-"}
              </figure>
            </IonCol>
            <IonCol size="12">
              <p>Vista Previa:</p>
              <EmbedFrame content={tv.src} source={tv.embed} /> 
            </IonCol>
          </IonRow>
        </IonGrid>}
    </ContentWraper>
  );
}

export default memo(TvPage);

