import { FC, memo } from "react";
import { useSelector } from 'react-redux';
import {
  IonButton 
} from "@ionic/react";
import {
  YokIcon,
  getTrack
} from "shared";
import "./styles.scss";

interface IProps {}
const WindForwardButton: FC<IProps> = () => {
  const track = useSelector(getTrack);
  
  const windBackward = () => {
    if (track === null) return;
    track.sound.setPosition(track.sound.position > 30000 ? track.sound.position - 30000 : 0)
  } 

  return (
    <IonButton 
      className='wind-backward'
      slot='icon-only'
      fill='clear'
      shape="round"
      onClick={windBackward}
      disabled={track === null || track.windable === false}
    >
      <YokIcon icon="replay_30" />
    </IonButton>
  )
}

export default memo(WindForwardButton)
