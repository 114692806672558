import { useState, useEffect, FC, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  IonButton,
  IonSpinner
} from '@ionic/react'
import {
  IDiagnosedStreamResource,
  setPlayerStatus,
  setTrack,
  getPlayer,
  getTrack,
  getPlayerStatus,
  YokIcon,
  ITrack
} from "shared";
import * as mime from 'mime';


import './styles.scss'

/**
 * station: station object in IStation interface
 * buttonType: choose what type of button must be used
 */
interface IProps {
  station: IDiagnosedStreamResource;
}

const StationButton: FC<IProps> = ({ 
  station
}) => {
  /**
   * States
   */
  const player = useSelector(getPlayer);
  const track = useSelector(getTrack);
  const playerStatus = useSelector(getPlayerStatus);
  const dispatch = useDispatch()
  const [current, setCurrent] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  /**
   * Functions
   */
  function getMimeType(filePath: string): string | false {
    const mimeType = mime.getType(filePath);
    if (!mimeType) {
      return false;
    }
    return mimeType;
  }
  
  const generateId = () => setId(`station_station_station_station_${station.url_name}`);

  const generateTrack = (): ITrack => {
    return {
      id: id,
      status: station.status,
      windable: false,
      icon_on: station.icon_on_file,
      icon_off: station.icon_off_file,
      source_url: station.source,
      type: station.type,
      length: "0",
      stats_url: station.stats_url,
      artist: station.public_name,
      title: station.public_name,
      artist_content_url: `/estacion/${station.url_name}`,
      title_content_url: `/estacion/${station.url_name}`,
      artwork: [
        {        
          src: station.artwork_file,
          sizes: `${station.artwork_height}x${station.artwork_width}`,
          type: getMimeType(station.artwork_file) || 'image/jpeg'
        }
      ],
      sound: player.createSound({
        id: id,
        url: station.src,
        volume: 100,
        multiShot: false,
        playbackRate: 1,
        stream: true,
        onbufferchange: isBuffering,
        ondataerror: onDataError,
        onerror: onError
      })
    }
  }

  const isBuffering = (buffering: number) => {
    console.info('buffering', buffering); 
    dispatch(setPlayerStatus(buffering === 1 ? "loading" : "playing"))
  }

  const onDataError = (error: any) => console.info('Error on data', error)

  const onError = (errorCode: string, description: string) => {
    console.info('Error playing Error code:' + errorCode)
    console.info('Error playing Error message:', description)
  }

  const createTrackAndPlay = () => {
    const newTrack = generateTrack();
    newTrack.sound.play();
    dispatch(setTrack(newTrack));
    dispatch(setPlayerStatus("playing"));
  }
  
  const pause = () => {
    if (track === null) return;
    if (track.sound.paused === false) track.sound.pause();
    dispatch(setPlayerStatus("pause"));
  }

  const play = () => {
    if (track === null) return;
    if (track.sound.paused) track.sound.play();
    dispatch(setPlayerStatus("playing"))
  }

  /**
   * Effects
   */
  useEffect(() => {
    if (track === null) return;
    setCurrent(track.id === id && station && station.status === "on-line");
    /* return () => setCurrent(false) */
  }, [track]);

  useEffect(() => {
    station && generateId();
  }, [station]);

  /**
   * Components
   */
  const Button: FC<{
    icon: React.ReactNode,
    onClick: React.MouseEventHandler
  }> = ({
    icon,
    onClick
  }) => (
    <IonButton
      className={['player-station-button' , ...(current ? ['active'] : [''])].join(" ")}
      aria-label={`Reproducir estación de radio ${station.public_name || ''}`}
      disabled={station.status !== "on-line"}
      onClick={onClick}
    >
      {icon}
    </IonButton>
  )

  if (station === undefined) return null;

  if (current && playerStatus === "loading") return <Button onClick={() => {}} icon={<IonSpinner name='crescent' />} />

  if (current && playerStatus === "pause") return <Button onClick={play} icon={<YokIcon icon="ra-play" />} />

  if (current && playerStatus === "playing") return <Button onClick={pause} icon={<YokIcon icon="ra-pause" />} />

  return <Button onClick={createTrackAndPlay} icon={<YokIcon icon="ra-play" />} />
}

export default memo(StationButton)
