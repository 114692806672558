import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent
} from '@ionic/react';
import { 
  getApiServer,
  getIcecastServer,
  getIcecastSources,
  getStations,
  StatusIcon
} from "shared";
import './styles.scss';

interface IProps {}

const IcecastStatusResume: FC<IProps> = () => {
  const apiServer = useSelector(getApiServer);
  const stations = useSelector(getStations);
  const icecastServer = useSelector(getIcecastServer);
  const icecastSources = useSelector(getIcecastSources);
  return (
    <section className="home-section icecast-status-resume">
      <h2>Servidores</h2>
      <div className="cards-container">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Status del servidor Icecast: <StatusIcon isAvailable={icecastServer.isAvailable} /></IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <ul>
              <li>
                <p>Disponibilidad para recibir: <StatusIcon isAvailable={icecastServer.serverStatus} /></p>
              </li>
              <li>
                <p>Disponibilidad para consumir: <StatusIcon isAvailable={icecastServer.sslStatus} /></p>
              </li>
              <li>
                <p>Recursos recibidos: <b>{icecastSources.length}</b></p>
              </li>
              {icecastServer.errorMessage !== "" && 
                <li>
                  <p className="error-message">Motivo de la falla: <span>{icecastServer.errorMessage}</span></p>
                </li>}
            </ul>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Status del servidor API: <StatusIcon isAvailable={apiServer.isAvailable} /></IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <ul>
              <li>
                <p>Servidor disponible: <StatusIcon isAvailable={apiServer.serverStatus} /></p>
              </li>
              <li>
                <p>SSL disponible: <StatusIcon isAvailable={apiServer.sslStatus} /></p>
              </li>
              <li>
                <p>Estaciones listadas: <b>{stations.length}</b></p>
              </li>
              {apiServer.errorMessage !== "" && 
                <li>
                  <p className="error-message">Motivo de la falla: <span>{apiServer.errorMessage}</span></p>
                </li>}
            </ul>
          </IonCardContent>
        </IonCard>
      </div>
    </section>
  );
}

export default memo(IcecastStatusResume);
