import { FC } from "react";
import { IonSpinner } from "@ionic/react";
import "./styles.scss";

interface IProps {
  loading?: boolean;
}
const FullScreenLoader: FC<IProps> = ({
  loading = true
}) => (
  <section className={["loader", "full-screen-loader", ...(loading ? ["loading"] : [])].join(" ")}>
    <IonSpinner name="crescent" />
  </section>
);

export default FullScreenLoader;
