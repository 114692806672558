import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon
} from '@ionic/react';
import { 
  getIcecastSources,
  getStations,
  StatusIcon,
  IDiagnosedStreamResource,
  StationButton
} from "shared";
import { searchOutline } from "ionicons/icons";
import PlayButton from "../PlayButton";
import './styles.scss';

interface IProps {}

const IcecastSoucesResume: FC<IProps> = () => {
  const icecastSources = useSelector(getIcecastSources);
  const stations = useSelector(getStations);

  const StationResume: FC<{station: IDiagnosedStreamResource}> = ({
    station
  }) => {
    const resource = icecastSources.filter(resource => resource.listenurl.includes(station.stream_id))[0];
    if (station === undefined || station === null) return null;
    return (
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{station.public_name}: <StatusIcon isAvailable={station.isAvailable} /></IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <ul>
            {resource && resource?.title  &&
              <li>
              <p>Reproduciendo: <span>{resource?.title || '-'}</span></p>
            </li>}
            {resource && resource?.listeners !== undefined   &&
              <li>
                <p>Cantidad de usuarios: <span>{resource?.listeners || '0'}</span></p>
              </li>}
            {station.errorMessage && station.errorMessage !== "" && 
              <li>
                <p className="error-message">Motivo de la falla: <span>{station.errorMessage}</span></p>
              </li>}
              
          </ul>
          <div className="card-actions">
          {station.url_name &&
            <Link to={`/estacion/${station.url_name}`}>
              <IonButton
                buttonType="button"
                fill="solid"
                shape="round"
                type="button"
              >
                <IonIcon icon={searchOutline} />
              </IonButton>
            </Link>}
            <StationButton 
              station={station}
            />
          </div>
        </IonCardContent>
      </IonCard>
    )
  }
  return (
    <section className="home-section icecast-souces-resume">
      <h2>Estaciones:</h2>
      <div className="cards-container">
        {stations && stations.length === 0 && <p>No hay estaciones listadas.</p>}
        {stations && stations.map(station => (<StationResume station={station} key={station.stream_id} />))}
      </div>
    </section>
  );
}

export default memo(IcecastSoucesResume);
