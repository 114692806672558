import { FC, useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { 
  startTvStreamCl 
} from './clappr';
import {
  fbAppId,
  TEmbeds
} from 'shared';
import "../styles.scss";

function adTwWidgets (elm: HTMLDivElement) {
  const script = document.createElement('script')
  script.setAttribute('src', 'https://platform.twitter.com/widgets.js')
  elm.appendChild(script)
}

function adIgEmbed (elm: HTMLDivElement) {
  const script = document.createElement('script')
  script.setAttribute('src', 'https://www.instagram.com/embed.js')
  elm.appendChild(script)
}

function fbInit (elm: HTMLDivElement) {
  const script = document.createElement('script')
  script.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
  elm.appendChild(script)
  if ('FB' in window) {
    window.FB.init(
      {
        appId: fbAppId,
        status: true,
        cookie: true,
        xfbml: true,
        version: 'v2.4'
      }
    )
  }
}

function adIframelyEmbed (elm: HTMLDivElement) {
  const script = document.createElement('script')
  script.setAttribute('src', 'https://cdn.iframe.ly/embed.js')
  elm.appendChild(script)
}

function fbPostType (content: string) {
  const compareWith = content.includes('<iframe') ? '%2Fvideos%2F' : '/videos/'
  return (content.includes(compareWith) === true ? 'video' : 'post')
}

/* 
function startTvStreamCl (elm: HTMLDivElement, data: any) {
  const player = new Clappr.Player({
    source: data.content,
    parentId: '.clappr',
    width: '100%'
  })
  return player;
}
*/

interface IData {
  content: string;
  source: string;
  type: 'video' | 'post' | 'videoplayer' | '';
  aspectRatio: '16by9' | '1by1' | '';
}

interface IProps {
  content: string;
  source: TEmbeds;
}

const EmbedFrame: FC<IProps> = ({ content, source }) => {
  const [data, setData] = useState<IData | undefined>(undefined);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (content === undefined || source === undefined) {
      setData(undefined);
    } else {
      const embed: IData = {
        content: content,
        source: source.toLowerCase(),
        type: '',
        aspectRatio: ''
      };
      switch (embed.source) {
        case 'youtube':
        case 'vimeo':
        case 'soundcloud':
        case 'twich':
          embed.type = 'video'
          embed.aspectRatio = '16by9'
          break
        case 'linkedin':
          embed.type = 'video'
          embed.aspectRatio = '1by1'
          break
        case 'facebook':
          embed.type = fbPostType(embed.content)
          if (embed.type === 'video') {
            embed.aspectRatio = '16by9'
          }
          break
        case 'twitter':
          embed.type = 'post'
          break
        case 'spotify':
        case 'instagram':
        case 'anchor':
          embed.type = 'post'
          break
        case 'tvstreamcl':
          embed.type = 'videoplayer'
          break
        default:
          embed.type = 'post'
      }
      setData(embed)
    }
  }, [content, source])

  useEffect(() => {
    if (data !== undefined && ref.current !== null) {
      if (data.source === 'twitter') {
        adTwWidgets(ref.current)
      }
      if (data.source === 'instagram') {
        adIgEmbed(ref.current)
      }
      if (data.source === 'iframely') {
        adIframelyEmbed(ref.current)
      }
      if (data.source === 'facebook' && data.content.includes('fb-root')) {
        fbInit(ref.current)
      }
      if (data.source === 'tvstreamcl') {
        startTvStreamCl(ref.current, data)
      }
    }
  }, [data])

  return (
    <div
      className='embed-wrapper'
      ref={ref}
    >
      {data?.type === 'video' &&
        <div className={`embed-responsive embed-responsive-${data.aspectRatio}`}>
          {parse(data.content)}
        </div>}

      {data?.type === 'post' &&
        <div className='embed-responsive-wrapper'>
          {parse(data.content)}
        </div>}

      {data?.type === 'videoplayer' &&
        <div id='' className='clappr embed-responsive embed-responsive-16by9' />}
    </div>
  )
}

export default EmbedFrame
