import { FC, memo } from 'react';
import IcecastSoucesResume from './IcecastSoucesResume';
import IcecastStatusResume from './IcecastStatusResume';
import { ContentWraper } from 'shared';
import './styles.scss';

interface IProps {
  route: any;
}

const HomePage: FC<IProps> = () => {
  return (
    <ContentWraper className='home-page'>
      <IcecastStatusResume />
      <IcecastSoucesResume />
    </ContentWraper>
  );
}

export default memo(HomePage);
