import { createSlice } from "@reduxjs/toolkit";
import {
  IicecastResource,
  IicecastServerAvailability,
  IDiagnosedStreamResource
} from "shared";


interface IinitialState {
  requestingStreams: boolean;
  stations: IDiagnosedStreamResource[];
  tv: IDiagnosedStreamResource | null;
  apiServer: IicecastServerAvailability;
  icecastServer: IicecastServerAvailability;
  icecastSources: IicecastResource[];
}
export const initialState : IinitialState = {
  requestingStreams: false,
  stations: [],
  tv: null,
  apiServer: {
    serverStatus: false,
    sslStatus: false,
    isAvailable: false,
    errorMessage: ""
  },
  icecastServer: {
    serverStatus: false,
    sslStatus: false,
    isAvailable: false,
    errorMessage: ""
  },
  icecastSources: []
};

const streamsSlice = createSlice({
  name: 'station',
  initialState: initialState,
  reducers: {
    setRequestingStreams: (state: IinitialState, { payload }: { payload: boolean} ) => {
      if (state.requestingStreams !== payload) state.requestingStreams = payload;
    },
    setStations: (state: IinitialState, { payload }: { payload: IDiagnosedStreamResource[]} ) => {
      if (state.stations.length === 0) {
        state.stations = payload;
      } else {
        // Loop Payload
        payload.forEach((station) => {
          // Find id in current state
          const index = state.stations.findIndex(object => {
            return object.stream_id === station.stream_id;
          });
          // If current does not exist on state, push it
          if (index === -1) {
            state.stations.push(station);
          // Else, merge objects
          } else {
            state.stations[index] = {...state.stations[index], ...station}
          }
        })
      }
    },
    setTV: (state: IinitialState, { payload }: { payload: IDiagnosedStreamResource | null} ) => {
      state.tv = state.tv === null ? payload : {...payload, ...state.tv};
    },
    setApiAvailability: (state: IinitialState, { payload }: { payload: IicecastServerAvailability} ) => {
      state.apiServer = payload;
    },
    setIcecastServerStatus: (state: IinitialState, { payload }: { payload: boolean} ) => {
      state.icecastServer.serverStatus = payload;
    },
    setIcecastSslStatus: (state: IinitialState, { payload }: { payload: boolean} ) => {
      state.icecastServer.sslStatus = payload;
    },
    setIcecastServerAvailability: (state: IinitialState, { payload }: { payload: IicecastServerAvailability} ) => {
      state.icecastServer = payload;
    },
    setIcecastSources: (state: IinitialState, { payload }: { payload: IicecastResource[]} ) => {
      if (state.stations.length === 0) {
        state.icecastSources = payload;
      } else {
        // Loop Payload
        payload.forEach((station) => {
          // Find id in current state
          const index = state.icecastSources.findIndex(object => {
            return object.listenurl === station.listenurl;
          });
          // If current does not exist on state, push it
          if (index === -1) {
            state.icecastSources.push(station);
          // Else, merge objects
          } else {
            state.icecastSources[index] = {...state.icecastSources[index], ...station}
          }
        })
      }
    },
  },
  extraReducers: (builder) => {}
});

export const {
  setRequestingStreams,
  setStations,
  setTV,
  setApiAvailability,
  setIcecastServerStatus,
  setIcecastSslStatus,
  setIcecastSources,
  setIcecastServerAvailability
} = streamsSlice.actions;
export default streamsSlice.reducer;
