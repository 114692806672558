import { FC, memo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact
} from '@ionic/react';
import { Navbar } from './components';
import { routes } from './routes';

setupIonicReact();

interface IProps {}

const AppDesktop: FC<IProps> = () => (
  <IonSplitPane
    contentId='main'
    when="xl"
  >
    <Navbar />
    <IonRouterOutlet id='main'>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
        >
          <route.layout>
            <route.component/>
          </route.layout>
        </Route>
      ))}
      <Route path="/home">
        <Redirect to="/" />
      </Route>
      <Redirect to='/' />
    </IonRouterOutlet>
  </IonSplitPane>
);

export default memo(AppDesktop);
