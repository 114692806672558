import { FC, memo } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
  YokIcon,
  getTrack,
  getPlayerStatus,
  setPlayerStatus
} from 'shared'
import {
  IonButton,
  IonSpinner
} from '@ionic/react'

import './styles.scss'

interface IProps {}
const DesktopPlayButton: FC<IProps> = () => {
  const track = useSelector(getTrack);
  const playerStatus = useSelector(getPlayerStatus);
  const dispatch = useDispatch()

  const pause = () => {
    if (track === null) return;
    if (track.sound.paused === false) track.sound.pause();
    dispatch(setPlayerStatus("pause"));
  }

  const play = () => {
    if (track === null) return;
    if (track.sound.paused) track.sound.play();
    dispatch(setPlayerStatus("playing"))
  }

  const Button: FC<{
    icon: React.ReactNode,
    onClick: React.MouseEventHandler,
    disabled?: boolean
  }> = ({
    icon,
    onClick,
    disabled = false
  }) => (
    <IonButton 
      className="dekstop-play-button toogle-play"
      slot='icon-only'
      fill='clear'
      shape="round"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </IonButton>
  )

  if (playerStatus === "loading") return <Button onClick={() => {}} icon={<IonSpinner name='crescent' />} />

  if (playerStatus === "playing") return <Button onClick={pause} icon={<YokIcon icon="ra-pause" />} />

  if (playerStatus === "pause") return <Button onClick={play} icon={<YokIcon className="play-icon" icon="ra-play" />} />

  return <Button disabled onClick={() => {}} icon={<YokIcon className="play-icon" icon="ra-play" />} />
}

export default memo(DesktopPlayButton);
