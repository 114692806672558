import loadable from '@loadable/component';
// Layout Types
import { DefaultLayout } from './layout';

// Route Views
const Home = loadable(() => import('shared/pages/Home'));
const Station = loadable(() => import('shared/pages/Station'));
const TV = loadable(() => import('shared/pages/TV'));

interface IRoutes {
  path: string,
  exact: boolean,
  layout: any,
  component: any
}
export const routes : IRoutes[] = [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: Home
  },
  {
    path: `/estacion/:public_name`,
    exact: true,
    layout: DefaultLayout,
    component: Station
  },
  {
    path: `/tv`,
    exact: true,
    layout: DefaultLayout,
    component: TV
  }
];

export const navOptions = [];
