import { useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import { 
  StreamingAPI,
  liveVideoId,
  setRequestingStreams,
  setStations,
  setTV,
  setApiAvailability,
  setIcecastServerAvailability,
  setIcecastSources,
  IicecastServerAvailability,
  IDiagnosedStreamResource,
  emptyStream
} from "shared";

/**
 * This components request streams status each 15 seconds
 * @returns void
 */
const StreamsUpdater = () => {
  const dispatch = useDispatch();

  /**
   * Function that request Streams Status
   */
  const getLiveResources = async () => {
    // Get actual request status

    // Dispatch requesting status
    dispatch(setRequestingStreams(true))

    // Get all stations listed on API
    const stations = await StreamingAPI.getStreamResource({
      type: "audio"
    })
    .then(response => response)
      .catch((e) => {
        console.error(e)
        return null;
      });

    // Get tv resource listed on API
    const liveVideo = await StreamingAPI.getStreamResource({
      stream_id: liveVideoId
    })
      .then(response => response)
      .catch((e) => {
        console.error(e)
        return null;
      });

    /* This is commented because this function doesn't work on production
    const serverStats = await StreamingAPI.getServerStats()
      .then(response => response)
      .catch((e) => {
        console.error(e)
        return null;
      });
    */

    const secureServerStats = await StreamingAPI.getSecureServerStats()
      .then(response => response)
      .catch((e) => {
        console.error(e)
        return null;
      });
    const icecastResources = secureServerStats !== null ? secureServerStats?.icestats?.source || [] : [];

    /**
     * After all the resources where requested, let's diagnose
     */
    const icecastServerAvailability: IicecastServerAvailability = {
      serverStatus: true, // !!serverStats,
      sslStatus: !!secureServerStats,
      isAvailable: !!secureServerStats, // !!serverStats && !!secureServerStats,
      errorMessage: ''
    }
    if (icecastServerAvailability.serverStatus === false && icecastServerAvailability.sslStatus === false) icecastServerAvailability.errorMessage = "El servidor de Icecast está apagado.";
    if (icecastServerAvailability.serverStatus === true && icecastServerAvailability.sslStatus === false) icecastServerAvailability.errorMessage = "El certificado de seguridad del servidor no es válido.";
    if (icecastServerAvailability.serverStatus === false && icecastServerAvailability.sslStatus === true) icecastServerAvailability.errorMessage = "Si está funcionando, no sabemos como, no debería. Revisa la conexión de internet.";

    const apiServerAvailability: IicecastServerAvailability = {
      serverStatus: stations !== null,
      sslStatus: stations !== null,
      isAvailable: stations !== null,
      errorMessage: stations === null ? 'El API de Yok Medios no está presentando problemas.' : ''
    }
    
    dispatch(setIcecastServerAvailability(icecastServerAvailability));
    dispatch(setApiAvailability(apiServerAvailability));
    dispatch(setIcecastSources(secureServerStats !== null ? secureServerStats?.icestats?.source || [] : []));

    const diagnosedStations: IDiagnosedStreamResource[] = (stations !== null ? stations : []).map((station) => {
      // Check if the resource is listed on Icecast as source 
      const listedInIcecast = icecastResources.some(resource => resource.listenurl.includes(station.stream_id));
      // Generate Diagnosed Stream Resource object
      const diagnosedStation = {
        ...station,
        isAvailable: icecastServerAvailability.isAvailable && listedInIcecast && apiServerAvailability.isAvailable,
        errorMessage: ""
      };
      // If icecast is not available, we extend the error message
      if (icecastServerAvailability.isAvailable === false) diagnosedStation.errorMessage = icecastServerAvailability.errorMessage;
      // If api is not available, we extend the error message
      if (apiServerAvailability.isAvailable === false) diagnosedStation.errorMessage = apiServerAvailability.errorMessage;
      // Tell the user that resource is not listed on icecast server
      if (icecastServerAvailability.isAvailable === true && apiServerAvailability.isAvailable === true && listedInIcecast === false) diagnosedStation.errorMessage = "Estación no listada en icecast. Se debe revisar el equipo de transmisión.";
      return diagnosedStation;
    })
    dispatch(setStations(diagnosedStations));

    const diagnosedTV: IDiagnosedStreamResource = {
      ...(liveVideo ? liveVideo[0] : emptyStream ),
      isAvailable: apiServerAvailability.isAvailable,
      // If api is not available, we extend the error message
      errorMessage: ""
    }
    if (apiServerAvailability.isAvailable === false) diagnosedTV.errorMessage = apiServerAvailability.errorMessage;
    if (apiServerAvailability.isAvailable === true && (liveVideo?.length === 0 || liveVideo === null)) diagnosedTV.errorMessage = "Live de TV no disponible. Verificar en el panel que se encuentre en status on-line";
    dispatch(setTV(diagnosedTV));


    // End request streams 
    dispatch(setRequestingStreams(false))
  }

  useEffect(() => {
    var interval: NodeJS.Timer;
    // call function when component render
    getLiveResources();
    // Set interval, so status will be requested each 10 seconds
    interval = setInterval(getLiveResources, 150000);
    return () => {
      // Clear interval when player is unmounted
      if (interval !== undefined || interval !== null) clearInterval(interval);
    }
  }, [])

  return (<></>)
}

export default memo(StreamsUpdater)
