import { FC, memo } from 'react';
import { IonIcon } from '@ionic/react';

interface IProps {
  icon: string;
  className?: string;
}
const YokIcon : FC<IProps> = ({ icon, className = "", ...props}) => {
  const iconFile = require(`./svg/${icon}.svg`);
  return <IonIcon className={className} src={iconFile} {...props} />
}

export default memo(YokIcon);
