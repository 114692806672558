export const appId = 5

export const appVersion = 2

export const appBasePath = process.env.NODE_ENV === 'production' ? 'https://yokradio.com/monitor' : 'https://yokradio.com/monitor'

export const staticResourcesPath = process.env.NODE_ENV === 'production' ? 'https://yokradio.com/assets' : 'https://yokradio.com/assets'

export const mainStreamingServiceUrl = 'https://stream.yokmedios.com:8001/'

export const gaTagId = {
  web: process.env.NODE_ENV === 'production' ? 'UA-119806067-6' : 'UA-119806067-7',
  android: process.env.NODE_ENV === 'production' ? 'UA-119806067-6' : 'UA-119806067-7',
  ios: process.env.NODE_ENV === 'production' ? 'UA-119806067-6' : 'UA-119806067-7'
}

export const fbAppId = process.env.NODE_ENV === 'production' ? '176257497885359' : '176257497885359'

export const fbPixelId = process.env.NODE_ENV === 'production' ? '568388307490038' : '568388307490038'

export const serverStatsUrl = "http://stream.yokmedios.com:8000/status-json.xsl";

export const secureServerStatsUrl = "https://stream.yokmedios.com:8001/status-json.xsl";
