import { FC, useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { getNowPlaying } from "shared";
import './styles.scss'

interface IProps {
  message?: string;
}

const Text: FC<{
  text: string;
  type: string;
  url: string;
}> = ({ 
  text,
  type,
  url
}) => {
  const history = useHistory();
  const ref = useRef<HTMLParagraphElement | null>(null)

  const onClick = () => {
    if (url === "") return;
    history.push(url)
  }

  useEffect(() => {
    if (ref.current === null) return;
    ref.current.style.animation = 'none'
    if (type === 'artist') {
      if ((window.innerWidth <= 991 && text.length >= 32) || (window.innerWidth > 991 && text.length >= 51)) {
        ref.current.style.animation = 'floatText 15s infinite ease-out'
      }
    } else {
      if ((window.innerWidth <= 991 && text.length >= 27) || (window.innerWidth > 991 && text.length >= 36)) {
        ref.current.style.animation = 'floatText 15s infinite ease-out'
      }
    }
  }, [text, type])

  if (text === "") return null;
  
  return <p ref={ref} onClick={onClick} className={type}>{text || ''}</p>
};

const MemoizedText = memo(Text);

const DesktopNowPlaying: FC<IProps> = () => {
  const nowPlaying = useSelector(getNowPlaying)

  return (
    <div className="desktop-now-playing">
      <figure>
        {nowPlaying.artwork !== '' && <IonImg src={nowPlaying.artwork} alt="artwork" />}
      </figure>
      <figcaption>
        <MemoizedText text={nowPlaying.title} url={nowPlaying.title_content_url} type='title' />
        <MemoizedText text={nowPlaying.artist} url={nowPlaying.artist_content_url} type='artist' />
      </figcaption>
    </div>
  );
}

export default memo(DesktopNowPlaying);
