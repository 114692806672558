import { FC, memo } from "react";
import { useSelector } from 'react-redux';
import {
  IonButton 
} from "@ionic/react";
import {
  YokIcon,
  getTrack,
} from "shared";
import "./styles.scss";

interface IProps {}
const WindBackwardButton: FC<IProps> = () => {
  const track = useSelector(getTrack);

  const windFordward = () => {
    if (track === null) return;
    if (track.sound.position + 30000 < track.sound.duration) track.sound.setPosition(track.sound.position + 30000);
  }

  return (
    <IonButton 
      className='wind-forward'
      slot='icon-only'
      fill='clear'
      shape="round"
      onClick={windFordward}
      disabled={track === null || track.windable === false}
    >
      <YokIcon icon="forward_30" />
    </IonButton>
  )
}

export default memo(WindBackwardButton)
