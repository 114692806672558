export const rootSettings = {
  site: 'Yok Medios',
  author: 'Yok Medios',
  ogType: 'website',
  title: 'Yok Medios - Últimas noticias de entretenimiento, Webshows y Podcast en español.',
  description: 'Disfruta de la últimas noticias de entretenimiento, cines y series, música, actualidad y mucho más, además de los mejores Webshow y Podcast en español 100% originales, desde Costa Rica.',
  keywords: 'Yok, Podcast en Español, News, Noticias en Español, Webshows en Español, Programas en Español, Streaming en vivo, Contenido de Entretenimiento, Noticias de Música, Fotos de Modelos, Fotografía de Modelaje',
  image: 'https://yokmedios.com/assets/preview_card.jpg',
  image_width: '1024',
  image_height: '1024'
}

export const newsSettings = {
  ogType: 'website',
  title: 'News - Últimas noticias en español de música, cines y series, videojuegos y entrenenimiento- Yok Medios ',
  description: 'Lee las noticias mas importantes del mundo de la música, lo más hablado del cine, lo más relevante de los videojuegos y mucho más del mundo del entretenimiento y actualidad.',
  keywords: 'Noticias en español de cine, Noticias en español de series, Noticias en español de música, Noticias en español de Videojuegos, Noticias en español de actualidad, Noticias en español de entretenimiento',
  image: 'https://yokmedios.com/assets/preview_card.jpg',
  image_width: '1024',
  image_height: '1024',
  navigation: {
    rows: 18
  }
}

export const mediaSettings = {
  ogType: 'website',
  title: 'Media - Explora los Webshows y Programas producidos por Yok Medios',
  description: 'Disfruta de Webshows y Programas en español originales, producidos por Yok Medios en Costa Rica',
  keywords: 'Webshows en español, Programas en español, Críticas en español, Programas de entretenimiento en español, Programas hechos en Costa Rica, Webshows hechos en Costa Rica',
  image: 'https://yokmedios.com/assets/preview_card.jpg',
  image_width: '1024',
  image_height: '1024',
  navigation: {
    rows: 12
  }
}

export const searchSettings = {
  rows: 18
}
