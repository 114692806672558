import { FC, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  useIonToast,
  IonIcon
} from "@ionic/react";
import {
  IDiagnosedStreamResource, 
  IicecastResource,
  getStations,
  getIcecastSources,
  ContentWraper,
  FullScreenLoader,
  StatusIcon
} from 'shared';
import './styles.scss';

interface IProps {
  route: any;
}

const StationPage: FC<IProps> = () => {
  const [present] = useIonToast();
  const history = useHistory();
  const { public_name } = useParams<{ public_name: string}>();
  const stations = useSelector(getStations);
  const icecastSources = useSelector(getIcecastSources);
  const [station, setStation] = useState<IDiagnosedStreamResource | null>(null);
  const [icecastSource, setIcecastSource] = useState<IicecastResource | null>();

  const presentToastError = () => {
    present({
      message: 'Estación inválida',
      duration: 1500,
      position: 'top',
      cssClass: 'error-toast'
    });
  };

  useEffect(() => {
    if (stations.length === 0) return;
    const currentStation = stations.filter(station => station.url_name === public_name)[0];
    if (currentStation === undefined) {
      presentToastError();
      history.push("/");
      return;
    }
    setStation(currentStation);
    const currentSource = icecastSources.filter(icecastSource => icecastSource.listenurl.includes(currentStation.stream_id))[0];
    setIcecastSource(currentSource !== undefined ? currentSource : null);
    console.info('currentSource', currentSource);
  }, [stations, public_name, icecastSources]); 

  useEffect(()=> {
    console.info('station', station);
    console.info('icecastSource', icecastSource);
  }, [station, icecastSource]);

  return (
    <ContentWraper className='station-page'>
      <FullScreenLoader loading={station === null} />
      {station !== null &&
        <IonGrid fixed>
          <IonRow>
            <IonCol size="12">
              <h1>Datos de la Estación <StatusIcon isAvailable={station?.isAvailable} /></h1>
              {station?.errorMessage && <p className='error-message'>{station?.errorMessage}</p>}
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Nombre:
                <span>{station?.public_name || "-"}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Descripción:
                <span>{station?.description || "-"}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Género:
                <span>{station?.genre || "-"}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Formato del Archivo:
                <span>{station?.file_format || "-"}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <figure>
                <figcaption>
                  <p>Icono de encencido:</p>
                </figcaption>
                {station?.icon_on_file ? <IonIcon src={station?.icon_on_file} /> : "-"}
              </figure>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <figure>
                <figcaption>
                  <p>Icono de apagado:</p>
                </figcaption>
                {station?.icon_off_file ? <IonIcon src={station?.icon_off_file} /> : "-"}
              </figure>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <figure>
                <figcaption>
                  <p>Imagen de Preview:</p>
                </figcaption>
                {station?.preview_image ? <IonImg src={station?.preview_image} /> : "-"}
              </figure>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <figure>
                <figcaption>
                  <p>Icono de Artwork:</p>
                </figcaption>
                {station?.artwork_file ? <IonImg src={station?.artwork_file} /> : "-"}
              </figure>
            </IonCol>
          </IonRow>
        </IonGrid>}
      
      {icecastSource !== null &&
        <IonGrid fixed>
          <hr className="app-divider" />
          <IonRow>
            <IonCol size="12">
              <h1>Datos del Stream</h1>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Escuchas:
                <span>{icecastSource?.listeners || 0}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Máximo de Escuchas:
                <span>{icecastSource?.listener_peak || 0}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Bitrate:
                <span>{icecastSource?.['ice-bitrate']}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Canales:
                <span>{icecastSource?.['ice-channels']}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Samplerate:
                <span>{icecastSource?.['ice-samplerate']}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Extensión de la pista:
                <span>{icecastSource?.server_type}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Inicio de la transmisión:
                <span>{icecastSource?.stream_start}</span>
              </p>
            </IonCol>
            <IonCol size="12" size-sm="6">
              <p>
                Reproduciendo:
                <span>{icecastSource?.title}</span>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>}
      
    </ContentWraper>
  );
}

export default memo(StationPage);
