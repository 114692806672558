import { combineReducers } from "redux";
import contentSlice from "./contentSlice";
import navigationSlice from "./navigationSlice";
import playerSlice from "./playerSlice";
import streamsSlice from "./streamsSlice";


const rootReducer = combineReducers({
  contents: contentSlice,
  navigation: navigationSlice,
  player: playerSlice,
  streams: streamsSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

export { setContents } from './contentSlice';
export { setShowReturnButton } from './navigationSlice';
export { 
  setPlayer,
  setMuted,
  setPlaying,
  setPlayerStatus,
  setSound,
  setTrack,
  setNowPlaying,
  setTrackProgress,
  setTrackDuration,
  setTrackTimeLeft,
  setTrackCurrentTime
} from './playerSlice';
export { 
  setRequestingStreams,
  setStations,
  setTV,
  setApiAvailability,
  setIcecastServerStatus,
  setIcecastSslStatus,
  setIcecastServerAvailability,
  setIcecastSources
} from './streamsSlice';
