import { createSlice } from "@reduxjs/toolkit";
import { 
  ITrack,
  TPlayerStatus,
  INowPlaying
} from "shared";

interface IinitialState {
  player: any;
  playerStatus: TPlayerStatus;
  muted: boolean;
  playing: boolean;
  sound: any;
  track: ITrack | null;
  nowPlaying: INowPlaying,
  trackProgress: number;
  trackDuration: number;
  trackTimeLeft: string;
  trackCurrentTime: string;
}
export const initialState : IinitialState = {
  player: null,
  muted: false,
  playing: false,
  playerStatus: "idle",
  sound: null,
  track: null,
  nowPlaying: {
    artwork: '',
    artist: 'Yok Radio',
    artist_content_url: '',
    title: 'Online Radio',
    title_content_url: ''
  },
  trackProgress: 0,
  trackDuration: 0,
  trackTimeLeft: '00:00',
  trackCurrentTime: '00:00'
};

const playerSlice = createSlice({
  name: 'player',
  initialState: initialState,
  reducers: {
    setPlayer: (state: IinitialState, action: any) => {
      const { payload } = action;
      state.player = payload;
    },
    setMuted: (state: IinitialState, action: { payload: boolean }) => {
      const { payload } = action;
      state.muted = payload;
    },
    setPlaying: (state: IinitialState, action:{ payload: boolean } ) => {
      const { payload } = action;
      state.playing = payload;
    },
    setPlayerStatus: (state: IinitialState, action: { payload: TPlayerStatus }) => {
      const { payload } = action;
      state.playerStatus = payload;
    },
    setSound: (state: IinitialState, action: any) => {
      const { payload } = action;
      state.sound = payload;
    },
    setTrack: (state: IinitialState, action: { payload: ITrack | null }) => {
      const { payload } = action;
      state.track = payload;
    },
    setNowPlaying: (state: IinitialState, action: { payload: INowPlaying }) => {
      const { payload } = action;
      state.nowPlaying = payload;
    },
    setTrackProgress: (state: IinitialState, action: { payload: number }) => {
      const { payload } = action;
      state.trackProgress = payload;
    },
    setTrackDuration: (state: IinitialState, action: { payload: number }) => {
      const { payload } = action;
      state.trackDuration = payload;
    },
    setTrackTimeLeft: (state: IinitialState, action: { payload: string }) => {
      const { payload } = action;
      state.trackTimeLeft = payload;
    },
    setTrackCurrentTime: (state: IinitialState, action: { payload: string }) => {
      const { payload } = action;
      state.trackCurrentTime = payload;
    },
  },
  extraReducers: builder => {}
});

export const {
  setPlayer,
  setMuted,
  setPlaying,
  setPlayerStatus,
  setSound,
  setTrack,
  setNowPlaying,
  setTrackProgress,
  setTrackDuration,
  setTrackTimeLeft,
  setTrackCurrentTime
} = playerSlice.actions;
export default playerSlice.reducer;
