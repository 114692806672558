import { createSlice } from "@reduxjs/toolkit";

interface IinitialState {
  showReturnButton: boolean;
}
export const initialState : IinitialState = {
  showReturnButton: false
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialState,
  reducers: {
    setShowReturnButton: (state: IinitialState, action: any) => {
      const { payload } = action;
      if (state.showReturnButton !== payload) state.showReturnButton = payload;
    }
  },
  extraReducers: builder => {}
});

export const {
  setShowReturnButton
} = navigationSlice.actions;
export default navigationSlice.reducer;
