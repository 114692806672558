import {
  TEmbeds
} from "./";

export type TStreamStatus = 'on-line' | 'off-line' | 'ended';

export interface IStreamResource {
  id: string;
  stream_id: string;
  src: string;
  stats_url: string;
  public_name: string;
  url_name: string;
  description: string;
  icon_on: string;
  icon_off: string;
  genre: string;
  id_type: string;
  type: "audio"  | "video";
  id_source: string;
  source: string;
  id_embed: string;
  embed: TEmbeds;
  id_provider: string;
  provider: string;
  file_format: string;
  id_status: string;
  status: TStreamStatus;
  preview_image: string;
  preview_image_width: string;
  preview_image_height: string;
  artwork_file: string;
  artwork_height: string;
  artwork_width: string;
  id_platform: string;
  plataform: string;
  icon_on_file: string;
  icon_off_file: string;
}

export const emptyStream: IStreamResource = {
  id: '',
  stream_id: "",
  src: "",
  stats_url: "",
  public_name: "",
  url_name: "",
  description: "",
  icon_on: "",
  icon_off: "",
  genre: "",
  id_type: "",
  type: "audio",
  id_source: "",
  source: "",
  id_embed: "",
  embed: "otro",
  id_provider: "",
  provider: "",
  file_format: "",
  id_status: "",
  status: "off-line",
  preview_image: "",
  preview_image_width: "",
  preview_image_height: "",
  artwork_file: "",
  artwork_height: "",
  artwork_width: "",
  id_platform: "",
  plataform: "",
  icon_on_file: "",
  icon_off_file: "",
}

export interface IDiagnosedStreamResource extends IStreamResource {
  isAvailable: boolean;
  errorMessage: string;
}

export interface IResourceArtwork {
  src: string;
  sizes: string;
  type: string;
}

export interface IStation {
  id: string;
  status: TStreamStatus;
  icon_off: string;
  icon_on: string;
  windable: false;
  source_url: string;
  stats_url: string;
  type: string;
  length: number;
  artist: string;
  title: string;
  public_name: string;
  url_name: string;
  description: string;
  artwork: IResourceArtwork[];
  preview_card: {
    src: string;
    width: number;
    height: number;
  },
  default: boolean;
}

export interface IicecastResource {
  audio_info: string;
  bitrate: number;
  genre: string;
  "ice-bitrate": number;
  "ice-channels": number;
  "ice-samplerate": number;
  listener_peak: number;
  listeners: number;
  listenurl: string;
  server_description: string;
  server_name: string;
  server_type: string;
  server_url: string;
  stream_start: string;
  stream_start_iso8601: string;
  title:  string;
  dummy: any;
}

export interface IicecastAllInstances {
  icestats: {
    admin: string;
    host: string;
    location: string;
    server_id: string;
    server_start: string;
    server_start_iso8601: string;
    source: IicecastResource[];
  }
}

export interface IicecastInstance {
  icestats: {
    admin: string;
    host: string;
    location: string;
    server_id: string;
    server_start: string;
    server_start_iso8601: string;
    source: IicecastResource;
  }
}

export interface IicecastServerAvailability {
  serverStatus: boolean;
  sslStatus: boolean;
  isAvailable: boolean;
  errorMessage: string;
}
