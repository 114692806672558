/* moment, $location, rootSettings, newsSettings, mediaSettings */
import {
  rootSettings,
  newsSettings,
  mediaSettings
} from '../config/pages'
import moment from 'moment'

const fields = [
  'indexing',
  'type',
  'author',
  'title',
  'keywords',
  'description',
  'url',
  'image',
  'image:width',
  'image:height',
  'profile:first_name',
  'profile:last_name',
  'profile:username',
  'article:published_time',
  'article:section'
]

const production = process.env.NODE_ENV === 'production'

export const setMetaTags = (options) => {
  for (const field of fields) {
    switch (field) {
      case 'indexing':
        setIndexingMetaTag(options[field])
        break
      case 'type':
        setTypeMetaTag(options[field])
        break
      case 'author':
        setAuthorMetaTag(options[field])
        break
      case 'title':
        setTitleMetaTag(options[field])
        break
      case 'keywords':
        setKeywordsMetaTag(options[field])
        break
      case 'description':
        setDescriptionMetaTag(options[field])
        break
      case 'url':
        setUrlMetaTag(options[field])
        break
      case 'image':
        setImageMetaTag(options[field])
        break
      case 'image:width':
        setImageWidthMetaTag(options[field])
        break
      case 'image:height':
        setImageHeightMetaTag(options[field])
        break
      case 'profile:first_name':
        setProfileFirstNameMetaTag(options[field])
        break
      case 'profile:last_name':
        setProfileLastNameMetaTag(options[field])
        break
      case 'profile:username':
        setProfileUsernameMetaTag(options[field])
        break
      case 'article:published_time':
        setArticlePublishedTimeMetaTag(options[field])
        break
      case 'article:section':
        setArticleSectionMetaTag(options[field])
        break
      default:
        console.error('')
        break
    }
  }
}

export const setIndexingMetaTag = (indexing) => {
  var index
  if (production === false) {
    index = false
  } else {
    index = (typeof indexing === 'undefined' || indexing === true)
  }
  try {
    document.querySelector('meta[name="robots"]').content = (index === true) ? 'index, follow' : 'noindex, nofollow'
    document.querySelector('meta[name="googlebot"]').content = (index === true) ? 'index, follow' : 'noindex, nofollow'
    document.querySelector('meta[name="bingbot"]').content = (index === true) ? 'index, follow' : 'noindex, nofollow'
  } catch (e) {
    logError('Error setting indexing')
  }
}

export const setTypeMetaTag = (type) => {
  try {
    document.querySelector('meta[property="og:type"]').content = type || 'website'
  } catch (e) {
    logError('Error setting type')
  }
}

export const setAuthorMetaTag = (author) => {
  try {
    document.querySelector('meta[name="author"]').content = author || rootSettings.author
  } catch (e) {
    logError('Error setting author')
  }
}

export const setTitleMetaTag = (title) => {
  try {
    document.querySelector('title').text = title || rootSettings.title
    document.querySelector('meta[name="title"]').content = title || rootSettings.title
    document.querySelector('meta[property="og:title"]').content = title || rootSettings.title
    document.querySelector('meta[name="twitter:title"]').content = title || rootSettings.title
  } catch (e) {
    logError('Error setting title')
  }
}

export const setKeywordsMetaTag = (keywords) => {
  try {
    document.querySelector('meta[name="keywords"]').content = keywords || rootSettings.keywords
  } catch (e) {
    logError('Error setting keywords')
  }
}

export const setDescriptionMetaTag = (description) => {
  var desc = description || rootSettings.description
  if (desc.length > 300) {
    desc = `${desc.substring(0, 297)}...`
  }
  try {
    document.querySelector('meta[name="description"]').content = desc
    document.querySelector('meta[property="og:description"]').content = desc
    document.querySelector('meta[name="twitter:description"]').content = desc
  } catch (e) {
    logError('Error setting description')
  }
}

export const setUrlMetaTag = (url) => {
  try {
    if (document.querySelector('link[rel=canonical]')) document.querySelector('link[rel=canonical]').href = url || window.location.href;
    if (document.querySelector('meta[property="og:url"]')) document.querySelector('meta[property="og:url"]').content = url || window.location.href;
  } catch (e) {
    logError('Error setting url')
  }
}

export const setImageMetaTag = (image) => {
  try {
    document.querySelector('meta[property="og:image"]').content = image || rootSettings.image
    document.querySelector('meta[property="og:image:secure_url"]').content = image || rootSettings.image
    document.querySelector('meta[name="twitter:image"]').content = image || rootSettings.image
  } catch (e) {
    logError('Error setting image')
  }
}

export const setImageWidthMetaTag = (width) => {
  try {
    document.querySelector('meta[property="og:image:width"]').content = width || ''
  } catch (e) {
    logError('Error setting image width')
  }
}

export const setImageHeightMetaTag = (height) => {
  try {
    document.querySelector('meta[property="og:image:height"]').content = height || ''
  } catch (e) {
    logError('Error setting image height')
  }
}

export const setProfileFirstNameMetaTag = (firstName) => {
  try {
    document.querySelector('meta[property="profile:first_name"]').content = firstName || ''
  } catch (e) {
    logError('Error setting profile first name')
  }
}

export const setProfileLastNameMetaTag = (lastName) => {
  try {
    document.querySelector('meta[property="profile:last_name"]').content = lastName || ''
  } catch (e) {
    logError('Error setting profile lasta name')
  }
}

export const setProfileUsernameMetaTag = (username) => {
  try {
    document.querySelector('meta[property="profile:username"]').content = username || ''
  } catch (e) {
    logError('Error setting profile username')
  }
}

export const setArticlePublishedTimeMetaTag = (publishedTime) => {
  var date = publishedTime || ''
  if (date !== '') {
    date = moment(publishedTime).toISOString()
  }
  try {
    document.querySelector('meta[property="article:published_time"]').content = date
  } catch (e) {
    logError('Error setting article published time')
  }
}

export const setArticleSectionMetaTag = (section) => {
  try {
    document.querySelector('meta[property="article:section"]').content = section || ''
  } catch (e) {
    logError('Error setting section')
  }
}

export const setRootMetaTags = (args) => {
  const params = args || {}
  setMetaTags({
    indexing: params.indexing,
    author: params.author || rootSettings.author,
    title: params.title || rootSettings.title,
    keywords: params.keywords || rootSettings.keywords,
    description: params.description || rootSettings.description,
    image: params.image || rootSettings.image,
    'image:width': params['image:width'] || rootSettings.image_width,
    'image:height': params['image:height'] || rootSettings.image_height
  })
}

export const setNewsMetaTags = (args) => {
  const params = args || {}
  setMetaTags({
    indexing: params.indexing,
    author: params.author || rootSettings.author,
    title: params.title || newsSettings.title,
    keywords: params.keywords || newsSettings.keywords,
    description: params.description || newsSettings.description,
    image: params.image || newsSettings.image,
    'image:width': params['image:width'] || newsSettings.image_width,
    'image:height': params['image:height'] || newsSettings.image_height
  })
}

export const setMediaMetaTags = (args) => {
  const params = args || {}
  setMetaTags({
    indexing: params.indexing,
    author: params.author || rootSettings.author,
    title: params.title || mediaSettings.title,
    keywords: params.keywords || mediaSettings.keywords,
    description: params.description || mediaSettings.description,
    image: params.image || mediaSettings.image,
    'image:width': params['image:width'] || mediaSettings.image_width,
    'image:height': params['image:height'] || mediaSettings.image_height
  })
}

const logError = (error) => {
  console.log(`%c ${error}`, 'color:#990000')
}
