import { staticResourcesPath } from './app';
import {
  IStation
} from "shared";


export const seasonalStation : IStation = {
  id: 'yr_seasonal_station',
  status: 'off-line',
  icon_off: 'ra-tiquiciairie-disconected',
  icon_on: 'ra-tiquiciairie',
  windable: false,
  source_url: '',
  stats_url: '',
  type: '',
  length: 0,
  artist: 'Yok Radio',
  title: '',
  public_name: 'Tiquicia Irie',
  url_name: 'tiquiciairie',
  description: '',
  artwork: [
    { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
    { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
    { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
    { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
    { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
    { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
  ],
  preview_card: {
    src: `${staticResourcesPath}/images/preview_card_tiquiciairie.jpg`,
    width: 1200,
    height: 630
  },
  default: false
}

export const stations: IStation[] = [
  {
    id: 'yr_urban',
    status: 'off-line',
    icon_off: 'ra-urban-disconected',
    icon_on: 'ra-urban',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: 0,
    artist: 'Yok Radio',
    title: 'Trendy',
    public_name: 'Trendy',
    url_name: 'trendy',
    description: 'Escucha las canciones más sonadas del momento o bien la música más Trendy, géneros como: reggaetón, pop, R&B y más.',
    artwork: [
      { src: `${staticResourcesPath}/artwork/urban/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/urban/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/urban/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/urban/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/urban/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/urban/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
    ],
    preview_card: {
      src: `${staticResourcesPath}/images/preview_card_trendy.jpg`,
      width: 1200,
      height: 630
    },
    default: false
  },
  {
    id: 'yr_classics',
    status: 'off-line',
    icon_off: 'ra-guitar-disconected',
    icon_on: 'ra-guitar',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: 0,
    artist: 'Yok Radio',
    title: 'Legend',
    public_name: 'Legend',
    url_name: 'legend',
    description: 'Revive las canciones que nunca pasarán de moda y que ahora son un clásico, música para recordar en sus diferentes géneros de Rock, Soul, Disco, Pop y muchos más.',
    artwork: [
      { src: `${staticResourcesPath}/artwork/classics/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/classics/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/classics/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/classics/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/classics/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/classics/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
    ],
    preview_card: {
      src: `${staticResourcesPath}/images/preview_card_legend.jpg`,
      width: 1200,
      height: 630
    },
    default: false
  },
  {
    id: 'yr_tropical',
    status: 'off-line',
    icon_off: 'ra-tropical-disconected',
    icon_on: 'ra-tropical',
    windable: false,
    source_url: '',
    type: '',
    length: 0,
    stats_url: '',
    artist: 'Yok Radio',
    title: 'Latin Sound',
    public_name: 'Latin Sound',
    url_name: 'latinsound',
    description: 'Música autóctona de Latinoamérica, los ritmos que corren por tus venas, entre estos se encuentran: Salsa, Merengue, Cumbia, Bachata, entre otros.',
    artwork: [
      { src: `${staticResourcesPath}/artwork/tropical/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tropical/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tropical/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tropical/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tropical/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tropical/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
    ],
    preview_card: {
      src: `${staticResourcesPath}/images/preview_card_latin.jpg`,
      width: 1200,
      height: 630
    },
    default: false
  },
  {
    id: 'yr_romantic',
    status: 'off-line',
    icon_off: 'ra-love-disconected',
    icon_on: 'ra-love',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: 0,
    artist: 'Yok Radio',
    title: 'Beat',
    public_name: 'Beat',
    url_name: 'beat',
    description: 'Canciones que te llegan al alma, que tocan las fibras de tu corazón, los artistas más representativos de grandes géneros como: Baladas en Español y en Inglés, Pop,  Románticas, Música Plancha, etc.',
    artwork: [
      { src: `${staticResourcesPath}/artwork/romantic/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/romantic/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/romantic/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/romantic/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/romantic/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/romantic/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
    ],
    preview_card: {
      src: `${staticResourcesPath}/images/preview_card_beat.jpg`,
      width: 1200,
      height: 630
    },
    default: false
  },
  {
    id: 'yr_live',
    status: 'off-line',
    icon_off: 'yr-microphone-disconected',
    icon_on: 'yr-microphone',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: 0,
    artist: 'Yok Radio',
    title: 'La Quinta',
    public_name: 'La Quinta',
    url_name: 'laquinta',
    description: 'Los mejores éxitos del Rock y el Pop en español, un viaje al pasado para recordar grandes bandas y máximos exponentes latinoamericanos de la música, con pequeñas intervenciones de programas en vivo.',
    artwork: [
      { src: `${staticResourcesPath}/artwork/live/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/live/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/live/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/live/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/live/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/live/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
    ],
    preview_card: {
      src: `${staticResourcesPath}/images/preview_card_quinta.jpg`,
      width: 1200,
      height: 630
    },
    default: true
  },
  {
    id: 'yr_reggae',
    status: 'off-line',
    icon_off: 'ra-tiquiciairie-disconected',
    icon_on: 'ra-tiquiciairie',
    windable: false,
    source_url: '',
    stats_url: '',
    type: '',
    length: 0,
    artist: 'Yok Radio',
    title: '',
    public_name: 'Tiquicia Irie',
    url_name: 'tiquiciairie',
    description: '',
    artwork: [
      { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-96.jpg`, sizes: '96x96', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-128.jpg`, sizes: '128x128', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-192.jpg`, sizes: '192x192', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-256.jpg`, sizes: '256x256', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-384.jpg`, sizes: '384x384', type: 'image/jpeg' },
      { src: `${staticResourcesPath}/artwork/tiquiciairie/artwork-512.jpg`, sizes: '512x512', type: 'image/jpeg' }
    ],
    preview_card: {
      src: `${staticResourcesPath}/images/preview_card_tiquiciairie.jpg`,
      width: 1200,
      height: 630
    },
    default: false
  }
]

export const urlPublicNames = {
  latinsound: 'yr_tropical',
  trendy: 'yr_urban',
  legend: 'yr_classics',
  beat: 'yr_romantic',
  live: 'yr_live',
  tiquiciairie: 'yr_reggae'
}

export const allStations = [...stations, seasonalStation]

export const getStationByUrlName = (urlName: string) => allStations.filter(item => item.url_name === urlName)[0]
