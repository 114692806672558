import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "shared/store/reducer";

const middelwares = [...getDefaultMiddleware({ serializableCheck: false })]

const store = configureStore({
  reducer: rootReducer,
  middleware: middelwares,
  ...(process.env.NODE_ENV !== "production" ? { devTools: true } : {})
})

export type RootState = ReturnType<typeof rootReducer>;
export { store };
