import { FC, useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import {
  getTrack,
  getPlayerStatus,
  getNowPlaying,
  setPlayerStatus
} from "shared";

const { CapacitorMusicControls } = Plugins;

interface IProps {}
/**
 * This component create and handle music session
 * @returns void 
 */
const MediaSession: FC<IProps> = ({}) => {
  const track = useSelector(getTrack)
  const nowPlaying = useSelector(getNowPlaying)
  const playerStatus = useSelector(getPlayerStatus)
  const [musicControls, setMusicControls] = useState<any>(false)
  const dispatch = useDispatch()

  const pause = () => {
    console.info('paussdsdddfgfgfvfv');
    if (track === null) return;
    if (track.sound.paused === false) track.sound.pause();
    dispatch(setPlayerStatus("pause"));
    navigator.mediaSession.playbackState = "paused";
  }

  const play = () => {
    console.info('plysdsdsdsds')
    if (track === null) return;
    if (track.sound.paused) track.sound.play();
    dispatch(setPlayerStatus("playing"));
    navigator.mediaSession.playbackState = "playing";
  }

  const createMediaSession = () => {
    if ('mediaSession' in navigator && track !== null) {
      console.info("track.artwork", track.artwork);
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: nowPlaying.title,
        artist: nowPlaying.artist,
        artwork: track.artwork || []
      })
      createMediaSessionHandler();
    }
  }

  const createMediaSessionHandler = () => {
    navigator.mediaSession.setActionHandler('play', play)
    navigator.mediaSession.setActionHandler('pause', pause)
    navigator.mediaSession.playbackState = 'none'
  }

  const createMusicControls = () => {
    if (isPlatform('cordova')) {
      if (musicControls) {
        CapacitorMusicControls.destroy();
      }
      CapacitorMusicControls.create({
        track: nowPlaying.title, // optional, default : ''
        artist: nowPlaying.artist, // optional, default : ''
        album: '',
        cover: track ? track.artwork[0].src : '', // optional, default : nothing
        // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
        // or a remote url ('http://...', 'https://...', 'ftp://...')

        // hide previous/next/close buttons:
        hasPrev: false, // show previous button, optional, default: true
        hasNext: false, // show next button, optional, default: true
        hasClose: false, // show close button, optional, default: false
        // iOS only, optional
        duration: 0, // optional, default: 0
        elapsed: 0, // optional, default: 0
        hasSkipForward: false, // optional, default: false. true value overrides hasNext.
        hasSkipBackward: false, // optional, default: false. true value overrides hasPrev.
        skipForwardInterval: 30, // optional. default: 15.
        skipBackwardInterval: 30, // optional. default: 15.
        hasScrubbing: false, // optional. default to false. Enable scrubbing from control center progress bar

        // Android only, optional
        isPlaying: playerStatus === "playing", // optional, default : true
        dismissable: true, // optional, default : false
        // text displayed in the status bar when the notification (and the ticker) are updated
        ticker: `Estás escuchando ${nowPlaying.artist} - ${nowPlaying.title}`,
        // All icons default to their built-in android equivalents
        // The supplied drawable name, e.g. 'media_play', is the name of a drawable found under android/res/drawable* folders
        playIcon: 'play_icon',
        pauseIcon: 'pause_icon',
        prevIcon: 'media_prev',
        nextIcon: 'media_next',
        closeIcon: 'media_close',
        notificationIcon: 'radio_icon'
      },
      () => {},
      (err: Error) => {
        console.info('Error creating music controls', err)
      })
      createMusicControlsHandler()
      setMusicControls(true)
    }
  }

  const createMusicControlsHandler = () => {
    if (isPlatform('android')) {
      CapacitorMusicControls.removeAllListeners()
    }
    CapacitorMusicControls.addListener('controlsNotification', handleControlsEvent)
  }

  const handleControlsEvent = (action: { message: string}) => {
    const message = action.message;
    switch (message) {
      case 'music-controls-next':
        // Do something
        break
      case 'music-controls-previous':
        // Do something
        break
      case 'music-controls-pause':
        pause()
        break
      case 'music-controls-play':
        play()
        break
      case 'music-controls-destroy':
        // Do something
        break
        // External controls (iOS only)
      case 'music-controls-toggle-play-pause' :
        if (track === null) return;
        if (track.sound.paused === false) {
          track.sound.pause();
          dispatch(setPlayerStatus("pause"));
        } else {
          track.sound.play();
          dispatch(setPlayerStatus("playing"))
        }
        break
      case 'music-controls-seek-to':
        /* const seekToInSeconds = JSON.parse(action).position
        MusicControls.updateElapsed({
          elapsed: seekToInSeconds,
          isPlaying: true
        }) */
        // Do something
        break
      case 'music-controls-skip-forward':
        // Do something
        break
      case 'music-controls-skip-backward':
        // Do something
        break
      // Headset events (Android only)
      // All media button events are listed below
      case 'music-controls-media-button' :
        if (track === null) return;
        if (track.sound.paused === false) {
          track.sound.pause();
          dispatch(setPlayerStatus("pause"));
        } else {
          track.sound.play();
          dispatch(setPlayerStatus("playing"))
        }
        break
      case 'music-controls-headset-unplugged':
        if (track === null) return;
        if (track.sound.paused === false) {
          track.sound.pause();
          dispatch(setPlayerStatus("pause"));
        }
        break
      case 'music-controls-headset-plugged':
        // Do something
        break
      default:
        break
    }
  }

  useEffect(() => {
    createMediaSession();
    createMusicControls();
  }, [nowPlaying, track]);

  useEffect(() => {
    if (playerStatus === "playing") {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = 'playing'
      }
      if (isPlatform('cordova')) {
        try {
          CapacitorMusicControls.updateIsPlaying({
            isPlaying: true
          })
        } catch (e) {
          createMusicControls()
        }
      }
    }
    if (playerStatus === "pause") {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = 'paused'
      }
      if (isPlatform('cordova')) {
        try {
          CapacitorMusicControls.updateIsPlaying({
            isPlaying: false
          })
        } catch (e) {
          createMusicControls()
        }
      }
    }
  }, [playerStatus]);
  
  useEffect(() => {
    navigator.mediaSession && createMediaSessionHandler();
    return () => {
      musicControls && CapacitorMusicControls.destroy();
    }
  }, []);

  return (<></>);
}

export default memo(MediaSession);
