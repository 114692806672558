import { Link } from 'react-router-dom';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
} from '@ionic/react';
import { 
  ReturnButton,
  YokIcon 
} from 'shared';
import './styles.scss';

/**
 * This component is the header, main component used on Layout
 * @returns React Component
 */
const Header = () => (
  <IonHeader className='desktop main-header'>
    <IonToolbar className='main-toolbar'>

      <ReturnButton />

      <IonButtons className='slot-center'>
        <IonButton>
          <Link
            className='brand-logo'
            to='/'
            aria-label='Ir a la página principal'
          >
            <YokIcon icon='radio_logo' />
          </Link>
        </IonButton>
      </IonButtons>

      <IonButtons slot='end'>
        <IonMenuButton />
      </IonButtons>

    </IonToolbar>
  </IonHeader>
)

export default Header
