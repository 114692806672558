import { createSlice } from "@reduxjs/toolkit";

interface IinitialState {
  contents: any[];
}
export const initialState : IinitialState = {
  contents: []
};

const contentSlice = createSlice({
  name: 'contents',
  initialState: initialState,
  reducers: {
    setContents: (state: IinitialState, action: any) => {
      const { payload } = action;
      state.contents = payload;
    }
  },
  extraReducers: builder => {}
});

export const {
  setContents
} = contentSlice.actions;
export default contentSlice.reducer;
