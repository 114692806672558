import { FC, memo } from "react";
import { IonIcon } from "@ionic/react"; 
import {
  checkmarkCircle,
  closeCircle
} from "ionicons/icons";
import "./styles.scss";



interface IProps {
  isAvailable: boolean;
}
const StatusIcon: FC<IProps> = ({
  isAvailable,
  ...props
}) => (<IonIcon icon={isAvailable ?  checkmarkCircle: closeCircle} className={["status-icon", isAvailable ?  "on" : "off"].join(" ")} {...props} />);
export default memo(StatusIcon)
